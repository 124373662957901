import React from 'react'
import { Link } from 'gatsby'
import Post from 'components/post';
import { Row, Col } from 'react-bootstrap';
import Layout from 'layouts';
import ReactPlayer from 'react-player'

export default (props) => (
  <Layout {...props}>
    <Row style={{margin: '0px 15px 30px 15px'}}>
      <Col md={{span: 8, offset: 2}}>
        <h3 style={{fontSize: '26px', textAlign: 'center', margin: '50px 0px'}}>ValueStream Ventures helps teams leverage valuable data assets to create industry-advancing products and services</h3>
        {/*<div style={{position: 'relative', paddingTop: '56.25%'}}>
          <ReactPlayer width='100%' height='100%' url='https://vimeo.com/394202762' style={{position: 'absolute', top: 0, left: 0}}/>
        </div>*/}
        <p style={{fontSize: '18px', color: 'rgb(59, 59, 59)', marginTop: '50px'}}>We approach each investment with a distinct strategy, leveraging tools, data, processes, relationships, and experience our team has built around the concept of valuable data assets. While we are flexible in our approach, we do have guiding principles about what we look for and how we support our companies. The following posts provide some additional insight into these principles and hopefully give a sense of what it is like to partner with us as an investor or founder.</p>
      </Col>
      {props.pageContext.posts.map(post => <Post post={post} button line summary path="approach" />)}
    </Row>
  </Layout>
)
